import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '../Text';
import { Arrow } from '../Arrow';

const StyledProjectsGallery = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 0;
  pointer-events: none;
  ${({ theme }) => theme.breakpoints.phablet`
    margin-bottom: ${rem(`24px`)};
  `}
  margin-bottom: ${rem(`12px`)};
  margin-top: ${rem(`16px`)};
  ::before,
  ::after {
    content: '';
    position: absolute;
    pointer-events: none;
  }
  ::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid ${({ theme }) => theme.colors.pink_1};
    ${({ theme }) => theme.breakpoints.tablet`
      border: 2px solid transparent;
      border-image: linear-gradient(
          ${theme.colors.pink_1},
          ${theme.colors.pink_2}
        )
        10% stretch;
    `}
  }
  ::after {
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    border: 1px solid ${({ theme }) => theme.colors.blue_1};
    border-radius: 2px;
  }
  .body1 {
    /* remove this crap, rethink Content component, this is lame */
    margin: 0 !important;
  }
  .projects-gallery__container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0;
    color: ${({ theme }) => theme.colors.white_1};
  }
  .projects-gallery__footer {
    display: flex;
    flex: 1 0 100%;
    align-items: center;
    justify-content: space-between;
    height: ${({ theme }) => theme.rhythmSizing(4)}rem;
    border-top: 1px solid ${({ theme }) => theme.colors.pink_1};
    margin: 0 -1px;
    z-index: 99;
    pointer-events: all;
  }
  .projects-gallery__arrow-left,
  .projects-gallery__arrow-right {
    display: flex;
    flex: 0 1 48px;
  }
  .projects-gallery__body {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    padding: 1px;
    a {
      display: flex;
      flex: 1 0 100%;
      width: 100%;
      height: 100%;
    }
  }
  .projects-gallery__image {
    width: 100%;
    height: 100%;
    pointer-events: all;
    position: relative;
    &::after {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Font Awesome 5 Pro';
      content: '\f00e';
      opacity: 0;
      visibility: hidden;
      color: ${({ theme }) => theme.colors.pink_1};
      text-shadow: 1px 1px 2px ${({ theme }) => theme.colors.blue_2};
      font-weight: 200;
      font-size: 48px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 48px;
      height: 48px;
      transition: all 1s ease-out;
    }
    img {
      overflow: hidden;
      zoom: 100%;
      transform: scale(1);
      ${({ theme }) => theme.breakpoints.tablet`
        animation: zoomBlurReverse 1s forwards;
      `}
    }
    ${({ theme }) => theme.breakpoints.tablet`
      :hover {
        cursor: pointer;
        &::after {
          animation: reveal 600ms forwards;
        }
        img {
          animation: zoomBlur 600ms forwards;
        }
      }
    `}
  }
  @keyframes zoomBlur {
    0% {
      opacity: 1;
      zoom: 100%;
      transform: scale(1);
    }
    100% {
      opacity: 0.3;
      zoom: 150%;
      transform: scale(1.1);
    }
  }
  @keyframes zoomBlurReverse {
    0% {
      opacity: 0.3;
      zoom: 150%;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      zoom: 100%;
      transform: scale(1);
    }
  }
  @keyframes reveal {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes revealReverse {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export interface IProjectsGallery {
  className?: string;
  blurbs: {
    slug: string;
    imageDescription: string;
    image: any;
  }[];
}

function isUnderBreakpoint(current, breakpoints) {
  return breakpoints.some((breakpoint) => breakpoint === current);
}

export const ProjectsGallery = ({ className, blurbs }: IProjectsGallery) => {
  const [index, setIndex] = React.useState(0);
  const breakpoints = React.useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const isUnderTablet = isUnderBreakpoint(breakpoint, [
    `watch`,
    `phone`,
    `phablet`,
    `tablet`,
  ]);
  const length = blurbs.length - 1;
  const handleNext = () => setIndex(index + 1);
  const handlePrevious = () => setIndex(index - 1);
  const { slug, imageDescription, image: galleryImage } = blurbs[index];
  const { description } = galleryImage;
  const image = getImage(galleryImage);
  return (
    <StyledProjectsGallery className={className}>
      <div className="projects-gallery__container">
        <div className="projects-gallery__body">
          {isUnderTablet ? (
            <GatsbyImage
              image={image}
              alt={description}
              className="projects-gallery__image"
            />
          ) : (
            <Link
              to={slug}
              state={{
                modal: true,
              }}
            >
              <GatsbyImage
                image={image}
                alt={description}
                className="projects-gallery__image"
              />
            </Link>
          )}
        </div>
        <div className="projects-gallery__footer">
          <Arrow
            onClick={() => handlePrevious()}
            disabled={index === 0}
            left
            className="projects-gallery__arrow-left"
          />
          <Text noRhythem type="body1" tag="span">
            {imageDescription}
          </Text>
          <Arrow
            onClick={() => handleNext()}
            disabled={index === length}
            right
            className="projects-gallery__arrow-right"
          />
        </div>
      </div>
    </StyledProjectsGallery>
  );
};
