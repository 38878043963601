import React from 'react';
import { graphql, Link } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import { CallToAction } from '@/components/CallToAction';
import { Rows } from '@/components/Rows';
import { Social } from '@/components/Social';
import Layout, { Main } from '../../components/Layout';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { ProjectsGallery } from '../../components/ProjectsGallery';
import { Button } from '../../components/Button';
import { TableRow } from '../../components/TableRow';
import { Seo } from '../../components/SEO';
import { StyledProject } from './styles';

interface IProjectPageTemplate {
  content: any;
  // eslint-disable-next-line react/no-unused-prop-types
  gallery?: any;
}

interface IProjectPage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulProject: any;
  };
  location: object;
}

const ProjectPageTemplate = ({ content, gallery }: IProjectPageTemplate) => {
  const {
    title,
    subtitle,
    client,
    description,
    processesApplied,
    caseStudy,
    period,
    serves,
    team,
    foundingYear,
  } = content;
  const table = [
    { title: `Client`, content: client.client },
    { title: `Founding Year`, content: foundingYear },
    { title: `Description`, content: description.description },
    {
      title: `Processes applied`,
      content: processesApplied.processesApplied,
    },
    { title: `Case study`, content: caseStudy.caseStudy },
    { title: `Period`, content: period.period },
    { title: `Serves`, content: serves.serves },
  ];

  return (
    <>
      <Content restrictWidth setMaxWidth="75%">
        <Text type="title1" tag="h2" color="white_1">
          {title}
        </Text>
        <Text type="subtitle1" tag="h3" color="white_1_45">
          {subtitle}
        </Text>
      </Content>

      <Content restrictWidth setMaxWidth="90%">
        <ProjectsGallery blurbs={gallery} />
      </Content>

      <Content restrictWidth setMaxWidth="90%" className="last">
        {table.map(({ title: tableTitle, content: tableContent }) => {
          return (
            <TableRow
              key={uuidv4()}
              category={tableTitle}
              content={tableContent}
            />
          );
        })}
        <TableRow category="Team members">
          {team.map(({ name, slug }, idx) => (
            <Text
              key={uuidv4()}
              noRhythem
              type="body1"
              tag="section"
              color="gray_3"
              className="link"
            >
              <Link to={`/about/${slug}`}>{name}</Link>
              {idx === team.length - 1 ? `` : `, `}
            </Text>
          ))}
        </TableRow>
      </Content>

      <Content restrictWidth shouldCenter setMaxWidth="90%">
        <Link to="/projects/">
          <Button size="large" disabled={false}>
            Back to Projects
          </Button>
        </Link>
      </Content>
    </>
  );
};

const ProjectPageDesktopTemplate = ({ content }: IProjectPageTemplate) => {
  const {
    subtitle,
    client,
    description,
    processesApplied,
    caseStudy,
    period,
    serves,
    team,
    foundingYear,
  } = content;
  const table = [
    { title: `Client`, content: client.client },
    { title: `Founding Year`, content: foundingYear },
    { title: `Description`, content: description.description },
    {
      title: `Processes applied`,
      content: processesApplied.processesApplied,
    },
    { title: `Case study`, content: caseStudy.caseStudy },
    { title: `Period`, content: period.period },
    { title: `Serves`, content: serves.serves },
  ];

  return (
    <div className="body">
      <Rows>
        <Content>
          <Text type="subtitle1" tag="h3" color="white_1_45">
            {subtitle}
          </Text>
        </Content>
        <Content>
          {table.map(({ title: tableTitle, content: tableContent }) => {
            return (
              <TableRow
                key={uuidv4()}
                category={tableTitle}
                content={tableContent}
              />
            );
          })}
          <TableRow category="Team members">
            {team.map(({ name, slug }, idx) => (
              <Text
                key={uuidv4()}
                noRhythem
                type="body1"
                tag="section"
                color="gray_3"
                className="link"
              >
                <Link to={`/about/${slug}`}>{name}</Link>
                {idx === team.length - 1 ? `` : `, `}
              </Text>
            ))}
          </TableRow>
        </Content>

        <Content shouldCenter noMargin>
          <Link to="/projects/">
            <Button size="large" disabled={false}>
              Back to Projects
            </Button>
          </Link>
        </Content>
      </Rows>
    </div>
  );
};

const ProjectPage = ({ data, location }: IProjectPage) => {
  const project = get(data, `contentfulProject`);
  const rootPage = get(data, `contentfulPage`);
  const {
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = rootPage;
  const {
    templateKey,
    title,
    subtitle,
    slug,
    metaTitle,
    metaDescription,
    client,
    description,
    processesApplied,
    caseStudy,
    period,
    serves,
    team,
    gallery,
    foundingYear,
  } = project;
  const sortedGallery = gallery.sort((a, b) => a.order - b.order);
  const hero = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);
  return (
    <StyledProject>
      <Layout
        location={location}
        coverImage={hero}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content>
            <Text type="title1" tag="h2" color="white_1">
              {title}
            </Text>
            <ProjectsGallery blurbs={gallery} />
          </Content>
        }
        desktopBody={
          <ProjectPageDesktopTemplate
            content={{
              subtitle,
              client,
              description,
              processesApplied,
              caseStudy,
              period,
              serves,
              team,
              foundingYear,
            }}
          />
        }
        desktopCta={
          <CallToAction
            hookId={ctaHookId}
            icon={ctaIcon}
            title={ctaTitle}
            body={ctaBody.ctaBody}
            maxWidth="100%"
          />
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={hero}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <Content>
            <ProjectPageTemplate
              content={{
                title,
                subtitle,
                client,
                description,
                processesApplied,
                caseStudy,
                period,
                serves,
                team,
                foundingYear,
              }}
              gallery={sortedGallery}
            />
          </Content>
          <CallToAction
            hookId={ctaHookId}
            icon={ctaIcon}
            title={ctaTitle}
            body={ctaBody.ctaBody}
          />
          <Social />
        </Main>
      </Layout>
    </StyledProject>
  );
};

export default ProjectPage;

export const ProjectPageQuery = graphql`
  query ProjectPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(contentful_id: { eq: "2zYL7QEbfowFMTB4MFh805" }) {
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      templateKey
      title
      subtitle
      slug
      metaTitle
      metaDescription
      client {
        client
      }
      foundingYear
      description {
        description
      }
      processesApplied {
        processesApplied
      }
      period {
        period
      }
      serves {
        serves
      }
      caseStudy {
        caseStudy
      }
      team {
        name
        slug
      }
      gallery {
        slug
        imageDescription
        order
        image {
          description
          gatsbyImageData(layout: FULL_WIDTH, quality: 85, placeholder: BLURRED)
        }
      }
    }
  }
`;
